// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import { router } from "./router/index";
import BootstrapVue from "bootstrap-vue";

import i18n from "@/plugins/i18n";

import Toasted from "vue-toasted";
import Mixin from "@/util/utilMixin";
import Vuex from "vuex";
import VueLazyload from "vue-lazyload";

// not required, to be deleted and tested
import axios from "axios";
import VueCookie from "vue-cookie";

Vue.use(VueCookie);
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

// ALL STYLES
// import './assets/css/animate.min.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/style.css";
import "./assets/css/button.css";
import "./assets/css/icons.css";
// Filter
import "./filters/filters";

import VueMaterialIcon from "vue-material-icon";
import VueSocketIO from "vue-socket.io";

export const EventBus = new Vue();
Vue.component(VueMaterialIcon.name, VueMaterialIcon);

/**
 * DATE TIME
 */
// You need a specific loader for CSS files
/**
 * DATE TIME
 */
let url = "";
console.log(window.location.hostname, "fsfdsf");
if (window.location.hostname == "192.168.1.189") {
  url = "http://localhost:3001";
} else {
  url = "http://localhost:3001";
}

let socket_options = {
  reconnectionDelay:2000, 
  reconnectionDelayMax :5000,
  transports: ["websocket"],
  upgrade: false
};

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: url,
    options: socket_options,
  })
);

// Import Store
import store from "./store";

Vue.use(Toasted, {
  duration: 3000,
  position: "top-center",
  singleton: true,
});

Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(Loading, {
  loader: "dots",
  color: "#007bff",
  width: 70,
  zIndex: 1019,
  height: 70,
  backgroundColor: "#4b4b4b",
});

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "/static/placeholder-image.png",
  loading: "/static/placeholder-image.png",
  attempt: 1,
});

Vue.mixin(Mixin);
Vue.http = Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.config.productionTip = false;

/* eslint-disable no-new */
global.vmVue = new Vue({
  i18n,
  el: "#app",
  store,
  router,
  components: {
    App,
  },
  template: "<App/>",
});
// global.vmVue = new Vue({
//   i18n,
//   el: '#app',
//   store,
//   router,
//   components: {
//     App
//   },
//   template: '<App/>'
// })
